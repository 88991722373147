import React from "react";
import "./track.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  LoadScript,
} from "@react-google-maps/api";
import { toast } from "react-toastify";

export default function TrackMap() {
  const { packageID } = useParams();

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({});
  const [directions, setDirections] = useState(null);
  const [destination, setDestination] = useState({});
  const [origin, setOrigin] = useState({});

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  useEffect(() => {
    setLoading(true);
    try {
      const getAllOrder = async () => {
        try {
          const res = await axios.get(
            `${baseUrl}/retailer/packages/tracking?id=${packageID}`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          if (res.status === 200) {
            setItem(res.data.data);
            setLoading(false);
          } else {
            toast.error("error");
          }
        } catch (error) {
          console.log(error);
        }
      };
      getAllOrder();
    } catch (error) {
      console.log(error);
    }
  }, [baseUrl, packageID, userToken]);

  useEffect(() => {
    const origin = {
      lat: item.pickupLocation?.latitude,
      lng: item.pickupLocation?.longitude,
    };

    const destination = {
      lat: item.destinationLocation?.latitude,
      lng: item.destinationLocation?.longitude,
    };

    setOrigin(origin);
    setDestination(destination);
  }, [
    item.destinationLocation?.latitude,
    item.destinationLocation?.longitude,
    item.pickupLocation?.latitude,
    item.pickupLocation?.longitude,
  ]);

  const directionsOptions = {
    destination: destination,
    origin: origin,
    travelMode: "TRANSIT",
  };

  const onDirectionsLoad = (directionsResult) => {
    setDirections(directionsResult);
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="loading_container">
        <ClipLoader size={60} color="#4b925b" />
      </div>
    );
  }

  return (
    <div className="track-map">
      <div className="track-map-area">
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            center={origin}
            zoom={11}
            mapContainerStyle={{ height: "100%", width: "100%" }}
          >
            {directions && <DirectionsRenderer directions={directions} />}
            <DirectionsService
              options={directionsOptions}
              callback={onDirectionsLoad}
            />
          </GoogleMap>
        </LoadScript>
      </div>
      <div className="track-package-details">
        <div className="track-package-detail">
          <h3>package ID: </h3>
          <p>{item.packageId}</p>
        </div>
        <div className="track-package-detail">
          <h3>pickup address: </h3>
          <p>{item.pickupAddress}</p>
        </div>
        <div className="track-package-detail">
          <h3>destination address: </h3>
          <p>{item.destinationAddress}</p>
        </div>
        <div className="track-package-detail">
          <h3>contact phone number: </h3>
          <p>{item.destinationContact}</p>
        </div>
        <div className="track-package-detail">
          <button onClick={() => navigate(-1)}>go back</button>
        </div>
      </div>
    </div>
  );
}
