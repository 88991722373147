import React, { useEffect } from "react";
import "./settings.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Settings() {
  const navigate = useNavigate();
  const baseuri = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const res = await axios.get(`${baseuri}/accounts/get_user`, null, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    getUserDetails();
  }, [baseuri, userToken]);

  return (
    <div className="settings">
      <h3 className="express-package-title pickupID_title">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack />
        </span>
        <span>Settings</span>
      </h3>
      <form className="settings_container">
        <div className="">
          <label htmlFor="">Full Name</label>
          <input type="text" />
        </div>
        <div className="">
          <label htmlFor="">Email address</label>
          <input type="email" />
        </div>
        <div className="">
          <label htmlFor="">Phone Number</label>
          <input type="text" />
        </div>
        <div className="">
          <button>save changes</button>
        </div>
      </form>
    </div>
  );
}
