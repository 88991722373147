import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./app.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Pages
import Starter from "./page/starter/Starter";
import Login from "./page/login/Login";
import Register from "./page/register/Register";
import Dashboard from "./page/dashboard/Dashboard";
import ProductDetails from "./page/product-details/ProductDetails";
import Regularshipping from "./page/regular-shipping/Regularshipping";
import ExpressShipping from "./page/express-shipping/ExpressShipping";
import UploadPackage from "./page/upload-package/UploadPackage";
import PackageDetails from "./page/package-detail/PackageDetails";
import NotFound from "./page/not-found/404";
import Tracking from "./page/tracking/Tracking";
import OfflineRiders from "./page/offline-riders/OfflineRiders";
import Pickups from "./page/pick-ups/Pickups";
import Payments from "./page/payment/Payment";
import Track from "./page/track/Track";
import Settings from "./page/settings/Settings";
import ForgetPassword from "./page/forget-password/ForgetPassword";
import TrackMap from "./page/track/TrackMap";
import Prices from "./page/prices/Prices";
import Support from "./page/support/Support";

function App() {
  const token = localStorage.getItem("arc_user_token") || null;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Starter />,
    },
    {
      path: "/dashboard",
      element: token === null ? <Login /> : <Dashboard />,
    },
    {
      path: "/ride-sharing",
      element: token === null ? <Login /> : <Regularshipping />,
    },
    {
      path: "/express-shipping",
      element: token === null ? <Login /> : <ExpressShipping />,
    },
    {
      path: "/upload-package",
      element: token === null ? <Login /> : <UploadPackage />,
    },
    {
      path: "/package-details",
      element: token === null ? <Login /> : <PackageDetails />,
    },
    {
      path: "/price-fare/:deliveryID",
      element: token === null ? <Login /> : <Prices />,
    },
    {
      path: "/finding-dispatch/:ride_choice/:orderId",
      element: token === null ? <Login /> : <Tracking />,
    },
    {
      path: "/offline-riders",
      element: token === null ? <Login /> : <OfflineRiders />,
    },
    {
      path: "/all-pickups",
      element: <Pickups />,
    },
    {
      path: "/all-pickups/:id",
      element: token === null ? <Login /> : <ProductDetails />,
    },
    {
      path: "/track",
      element: token === null ? <Login /> : <Track />,
    },
    {
      path: "/track-package/:packageID",
      element: token === null ? <Login /> : <TrackMap />,
    },
    {
      path: "/payments",
      element: token === null ? <Login /> : <Payments />,
    },
    {
      path: "/support",
      element: token === null ? <Login /> : <Support />,
    },
    {
      path: "/settings",
      element: token === null ? <Login /> : <Settings />,
    },
    {
      path: "/forget-password",
      element: <ForgetPassword />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <div className="app">
      <RouterProvider router={router} />

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
