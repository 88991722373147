import React from "react";
import RegisterForm from "../../component/register-form/RegisterForm";
import "./register.css";

function Register() {
  return (
    <div className="register">
      <div className="register_container">
        <RegisterForm />
      </div>
    </div>
  );
}

export default Register;
