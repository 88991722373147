import React from 'react';
import './forget-password.css';
import ForgottenForm from '../../component/forgotten-form/ForgottenForm';
import { Link } from 'react-router-dom';

export default function ForgetPassword() {
  return (
    <>
      <section className="forgotten">
        <div className="forgotten_form login_container">
          <ForgottenForm />
          <Link to="/login" style={{ position: 'absolute', bottom: '1rem' }}>
            Back
          </Link>
        </div>
      </section>
    </>
  );
}
