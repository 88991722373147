import React from 'react';
import './pickup.css';
import { Link } from 'react-router-dom';

export default function PickUp({ status, id }) {
  return (
    <div className="pickup">
      <div className="pickup_container">
        <div>
          <span>Order ID:</span> <b>{id} </b>
        </div>
        <div>
          <span> status:</span> <b>{status}</b>
        </div>
      </div>
      <div className="pickup_btn">
        <Link to={`/all-pickups/${id}`}>See more</Link>
      </div>
    </div>
  );
}
