import axios from "axios";

const geocodeAddress = async (address) => {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
        address
      )}`
    );

    if (response.data && response.data.length > 0) {
      const { lat, lon, name } = response.data[0];
      return {
        latitude: lat,
        longitude: lon,
        address: name,
        addList: response.data,
      };
    } else {
      console.log("error: unknown");
    }
  } catch (error) {
    console.error("Error during geocoding:", error.message);
    throw error;
  }
};

const reverseGeocode = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );

    if (response.data) {
      const { display_name } = response.data;
      return display_name;
    } else {
      throw new Error("No results found for the given coordinates");
    }
  } catch (error) {
    console.error("Error during reverse geocoding:", error.message);
    throw error;
  }
};

export { geocodeAddress, reverseGeocode };
