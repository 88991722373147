import React, { useState } from "react";
import arcLogo from "../../asset/image/arc_logo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { ClipLoader } from "react-spinners";

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPending, setIsPending] = useState(false);

  const baseuri = process.env.REACT_APP_BASE_URI;

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsPending(true);
    const body = { email, password };

    try {
      const res = await axios.post(`${baseuri}/accounts/login`, body);
      console.log(res);
      if (res.status === 200) {
        if (res.data.msg === "you account is currently not active") {
          setIsPending(false);
          toast.warning(
            "you account is currently not active, Check your email to confirm your account"
          );
        } else if (
          res.data.msg === "password is incorrect" ||
          res.data.msg === "email is incorrect"
        ) {
          toast.error("Incorrect email or password");
          setIsPending(false);
        } else if (res.data.msg === "login successful") {
          setIsPending(false);
          toast.success("Login Successful");
          localStorage.setItem("arc_user_token", res.data.body.token);
          sessionStorage.setItem("arc_user_email", res.data.body.email);
          sessionStorage.setItem("arc_user_phone", res.data.body.phone);
          sessionStorage.setItem("arc_first_name", res.data.body.first_name);
          sessionStorage.setItem("arc_last_name", res.data.body.last_name);
          console.log(res.data);
          window.location.href = "/dashboard";
          // navigate("/dashboard");
          // window.location.reload();
        }
      }
    } catch (error) {
      setIsPending(false);
      toast.error(error.message);
    }
  };

  return (
    <div className="login_form">
      <div className="login_form_top">
        <div className="arc_logo_container">
          <img src={arcLogo} alt="ARC LOGO" className="arc_logo" />
        </div>
      </div>

      <form onSubmit={handleLogin} className="login_form_bottom">
        <div className="input_group">
          <label htmlFor="">email address</label>
          <input
            autoFocus
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input_group">
          <label htmlFor="">password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="input_group">
          {isPending ? (
            <button disabled>
              <ClipLoader color="#fff" />
            </button>
          ) : (
            <button>Login</button>
          )}
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
