import React from "react";
import "./location-prompt.css";

const LocationPrompt = ({ handleGrantAccess }) => {
  const handleGrant = () => {
    handleGrantAccess();
  };

  return (
    <div className="location_prompt">
      <div className="location_prompt_container">
        <p>
          This website uses your location, Please enable location services to
          continue. click the button below to continue
        </p>

        <div className="">
          <button onClick={handleGrant}>check</button>
        </div>
      </div>
    </div>
  );
};

export default LocationPrompt;
