import React from "react";
import "./payment.css";
// import { useState } from 'react';
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { FaMotorcycle } from "react-icons/fa";
import { RiEBikeLine } from "react-icons/ri";
import { AiFillCar } from "react-icons/ai";

function Payments() {
  const navigate = useNavigate();
  const transports = [
    {
      id: 1,
      mode: "Bike",
      pickupAddress: "123 Main St, New York, NY",
      deliveryAddress: "456 Oak St, New York, NY",
      amount: 15.0,
      date: "2023-05-12",
      time: "10:00 AM",
    },
    {
      id: 2,
      mode: "Tricycle",
      pickupAddress: "789 Elm St, New York, NY",
      deliveryAddress: "1011 Pine St, New York, NY",
      amount: 20.0,
      date: "2023-05-13",
      time: "2:30 PM",
    },
    {
      id: 3,
      mode: "Car",
      pickupAddress: "1213 Maple St, New York, NY",
      deliveryAddress: "1415 Cherry St, New York, NY",
      amount: 25.0,
      date: "2023-05-14",
      time: "9:00 AM",
    },
    // ... and so on, up to 20 objects in total
  ];

  return (
    <div className="payments">
      <h3 className="payment-title express-package-title">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack />
        </span>
        <span>All Transactions</span>
      </h3>
      <div className="payments_container">
        {transports.length > 0 ? (
          transports.map((info) => {
            return (
              <Link to={`/all-pickups/${info.id}`}>
                <div className="payment-card">
                  <div className="pickup-mode">
                    {info.mode === "Bike" && <FaMotorcycle />}
                    {info.mode === "Tricycle" && <RiEBikeLine />}
                    {info.mode === "Car" && <AiFillCar />}
                  </div>
                  <div className="payment-pickup-address">
                    <p>{info.pickupAddress}</p>
                    <small>
                      {info.date} {info.time}
                    </small>
                  </div>
                  <div className="payment-amount">
                    <b>₦{info.amount}00</b>
                    <small>Paid</small>
                  </div>
                </div>
                <div className="line"></div>
              </Link>
            );
          })
        ) : (
          <div>
            <h4>No Pickups yet</h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default Payments;
