import React from "react";
import "./offline-riders.css";
import { FaBars } from "react-icons/fa";
import { useGlobalContext } from "../../context";
import Sidebar from "../../component/sidebar/Sidebar";
import DispatchID from "../../component/dispatchID/DispatchID";

export default function OfflineRiders() {
  const { openSidebar, closeSidebar } = useGlobalContext();
  const rides = [
    {
      riderName: "emmanuel igwenagu",
      phone: "8130927634",
      time: 4,
      type: "bike",
      price: "3000",
      rideType: "regular",
    },
    {
      riderName: "emma igwe",
      phone: "8130927634",
      time: 4,
      type: "van",
      price: "2000",
      rideType: "premium",
    },
    {
      riderName: "nuel nagu",
      phone: "8130927634",
      time: 4,
      type: "tricycle",
      price: "1500",
      rideType: "regular",
    },
    {
      riderName: "emma nagu",
      phone: "8130927634",
      time: 4,
      type: "car",
      price: "6000",
      rideType: "regular",
    },
    {
      riderName: "nedu nagu",
      phone: "8130927634",
      time: 4,
      type: "bike",
      price: "3000",
      rideType: "premium",
    },
    {
      riderName: "nuel agu",
      phone: "8130927634",
      time: 4,
      type: "bike",
      price: "3000",
      rideType: "regular",
    },
    {
      riderName: "cynthia igwe",
      phone: "8130927634",
      time: 4,
      type: "bike",
      price: "3000",
      rideType: "regular",
    },
  ];

  return (
    <section>
      <div className="menu_icon" onClick={openSidebar}>
        <FaBars />
      </div>
      <div className="offline-riders" onClick={closeSidebar}>
        <div className="title-offline">
          <p>
            Make sure you complete all transactions within the app for safety
            reasons{" "}
          </p>
        </div>
        {rides.map((ride, index) => {
          return (
            <DispatchID
              key={index}
              riderName={ride.riderName}
              phone={ride.phone}
              time={ride.time}
              type={ride.type}
              price={ride.price}
              rideType={ride.rideType}
            />
          );
        })}
      </div>

      <Sidebar />
    </section>
  );
}
