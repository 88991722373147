import React, { useState } from "react";
import DispatchIcon from "../../asset/image/dispatchIcon.jpg";
import "./DispatchID.css";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function DispatchID({
  time,
  type,
  phone,
  price,
  riderId,
  riderName,
  profile_pics,
  orderId,
}) {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  const handleAssign = async () => {
    setLoading(true);
    const url = `${baseUrl}/retailer/assign_rider?id=${orderId}&rider=${riderId}`;
    console.log(url);
    try {
      const res = await axios.put(url, null, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (res.data.message === "success") {
        toast.success("Order placed");
        sessionStorage.removeItem("arc_packages");
        sessionStorage.removeItem("packages_details");
        sessionStorage.removeItem("bicycleFare");
        sessionStorage.removeItem("carFare");
        sessionStorage.removeItem("bikeFare");
        sessionStorage.removeItem("vanFare");
        sessionStorage.removeItem("tricycleFare");
        setActive(true);
        // navigate("/all-pickups");
      }
      console.log(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="dispatchID">
      <div className="dispatchIDTop">
        <div className="dispatchIDTopContainer">
          <div className="dispatchIDImageContainer">
            <img
              src={profile_pics ? profile_pics : DispatchIcon}
              alt={riderName}
            />
          </div>
          <div className="dispatchIDDetailContainer">
            <h3>{riderName}</h3>
            <p>{type}</p>
          </div>
        </div>
        {/* <div className="dispatchIDPricing">
          <h5>
            <span>₦{price}</span>
          </h5>
          <h5>
            <span>{time} hours</span>
          </h5>
        </div> */}
      </div>
      {active ? (
        <div className="dispatchIDBottom">
          <a href={`tel:${phone}`}>Call</a>
        </div>
      ) : (
        <div className="dispatchIDBottom">
          {loading ? (
            <button disabled>
              <ClipLoader size={30} color="#fff" />
            </button>
          ) : (
            <button onClick={handleAssign}>select dispatcher</button>
          )}
        </div>
      )}
    </div>
  );
}

export default DispatchID;
