import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../asset/image/arc_logo.png';
import './starter.css';

function Starter() {
  return (
    <div className="starter">
      <div className="starter_container">
        <div className="starter_top">
          <div className="starter_logo_container">
            <img src={logo} alt="ARC LOGO" className="arc-logo" />
          </div>
        </div>
        <div className="starter_bottom">
          <Link to="/login" className="starterbutton">
            <span>Login</span>
          </Link>
          <Link to="/register" className="starterbutton">
            <span>Register</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Starter;
