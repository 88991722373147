import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arcLogo from "../../asset/image/arc_logo.png";
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function RegisterForm() {
  const inputRef = useRef(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible(!visible);
  };

  const baseuri = process.env.REACT_APP_BASE_URI;

  const navigate = useNavigate();

  const type = "retailer";

  const handleRegister = async (e) => {
    e.preventDefault();
    if (
      email === "" ||
      phone === "" ||
      password === "" ||
      firstname === "" ||
      lastname === "" ||
      businessName === ""
    ) {
      toast.error("All fields are required");
      return;
    } else {
      setIsPending(true);
      const body = {
        phone,
        email,
        password,
        type,
        first_name: firstname,
        last_name: lastname,
        business_name: businessName,
      };

      try {
        const res = await axios.post(`${baseuri}/accounts/register`, body);

        if (res.status === 200) {
          setIsPending(true);
          toast.success(
            "Account created successfully. Please check your mail to verify your account"
          );
          setIsPending(false);
          setTimeout(() => {
            navigate(`/login`);
          }, 1000);
        }
      } catch (error) {
        setIsPending(false);
        toast.error(
          error.message === "Request failed with status code 400"
            ? "Please details already exists"
            : "Poor internet connection"
        );
      }
    }
  };

  return (
    <div className="register_form">
      <div className="register_form_top">
        <div className="arc_logo_container">
          <img src={arcLogo} alt="ARC LOGO" className="arc_logo" />{" "}
        </div>
      </div>

      <form className="register_form_bottom" onSubmit={handleRegister}>
        <div className="input_group_row">
          <div className="input_group">
            <label htmlFor="">first name</label>
            <input
              ref={inputRef}
              type="text"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="input_group">
            <label htmlFor="">last name</label>
            <input
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
        </div>
        <div className="input_group">
          <label htmlFor="">email address</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input_group">
          <label htmlFor="">Business name (Optional)</label>
          <input
            type="text"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          {/* <small>You are entitled to 150 naira for every trip</small> */}
        </div>
        <div className="input_group">
          <label htmlFor="">Phone number</label>
          <input
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="input_group">
          <label htmlFor="">password</label>
          <input
            type={visible === true ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {visible ? (
            <div className="password_container">
              <FaEyeSlash onClick={handleVisible} />
            </div>
          ) : (
            <div className="password_container">
              <FaEye onClick={handleVisible} />
            </div>
          )}
        </div>

        <div className="input_group">
          {isPending ? (
            <button disabled>
              <ClipLoader color="#fff" />
            </button>
          ) : (
            <button>Register</button>
          )}
        </div>
      </form>
      <div className="form_bottom_text">
        <Link to="/login">Already have an account? Login</Link>
      </div>
    </div>
  );
}

export default RegisterForm;
