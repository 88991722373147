import React from "react";
import "./address.css";
import { FaMapMarkerAlt } from "react-icons/fa";

export default function AddressOptions({ onAddressSelect, addresses }) {
  const handleAddressClick = (address) => {
    onAddressSelect(address.display_name);
  };

  return (
    <section className="address-history">
      <div className="border-bottom"></div>
      {addresses.slice(0, 4).map((address, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className="address-details"
              onClick={() => handleAddressClick(address)}
            >
              <div className="address-icon">
                <FaMapMarkerAlt />
              </div>
              <div className="details">
                <b>{address.display_name} </b>
              </div>
            </div>
            <div className="border-bottom"></div>
          </React.Fragment>
        );
      })}
    </section>
  );
}
