import React, { useCallback, useEffect, useState } from "react";
import "./package-details.css";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../context";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { geocodeAddress } from "../../helpers/geocode";

export default function ExpressPackageDetails() {
  const { packages, removePackage } = useGlobalContext();

  const location = useLocation();

  const [pickupAddress, setPickupAddress] = useState("");
  const [pickupLatLng, setPickupLatLng] = useState({});
  const [destinationAddress, setDestinationAddress] = useState("");
  const [ridePackages, setRidePackages] = useState([]);
  const [rideShareCo, setRideShareCo] = useState({});
  const [rideType, setRideType] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const userToken = localStorage.getItem("arc_user_token");
  const baseUrl = process.env.REACT_APP_BASE_URI;

  const apikey = process.env.REACT_APP_GOOGLE_API_KEY;

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userToken}`,
    },
  };

  const categories = [
    { id: "1", label: "Books, Documnets, Files" },
    { id: "2", label: "Food items" },
    { id: "3", label: "Machine" },
    { id: "4", label: "Clothing Accessories" },
    { id: "5", label: "Laptop, Mobile, other gadgets" },
    { id: "6", label: "Jewelry, Other Valuables" },
    { id: "7", label: "Other" },
  ];

  // useEffect(() => {
  //   if (packages.length === 0) {
  //     window.location.reload();
  //   }
  // }, [packages]);

  useEffect(() => {
    const storedAddresses = sessionStorage.getItem("arc_addresses");
    if (storedAddresses) {
      const parsedAddresses = JSON.parse(storedAddresses);

      if (parsedAddresses.length > 0) {
        setPickupAddress(parsedAddresses[0]);
        setDestinationAddress(parsedAddresses[1]);
      }
    }
  }, []);

  // const geocodeAddress = useCallback(
  //   async (address) => {
  //     try {
  //       const response = await axios.get(
  //         "https://maps.googleapis.com/maps/api/geocode/json",
  //         {
  //           params: {
  //             address: address,
  //             key: apikey,
  //           },
  //         }
  //       );

  //       if (response.data.results.length > 0) {
  //         const { lat, lng } = response.data.results[0].geometry.location;
  //         return { latitude: lat, longitude: lng };
  //       } else {
  //         console.log("less than expected");
  //         return null;
  //       }
  //     } catch (error) {
  //       console.log("what could not be wrong");
  //       console.error(error);
  //       return null;
  //     }
  //   },
  //   [apikey]
  // );

  useEffect(() => {
    const getPickupLocation = async () => {
      try {
        if (pickupAddress) {
          const location = await geocodeAddress(pickupAddress);
          const latlng = {
            lat: location.latitude,
            lng: location.longitude,
          };

          console.log(latlng);
          console.log(location);

          setPickupLatLng(latlng);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getPickupLocation();
  }, [geocodeAddress, pickupAddress]);

  useEffect(() => {
    if (rideType === "ride_share") {
      const convertAddressToCoordinates = async () => {
        try {
          const res = await geocodeAddress(destinationAddress);
          setRideShareCo(res);
        } catch (error) {
          console.error(
            "Error converting pickup address to coordinates:",
            error.message
          );
        }
      };

      convertAddressToCoordinates();
    }
  }, [destinationAddress, geocodeAddress, rideType]);

  useEffect(() => {
    if (rideType === "express") {
      const getPackageCoordinates = async () => {
        const updatedPackages = [];

        for (const pack of packages) {
          try {
            const destinationLocation = await geocodeAddress(
              pack.destinationAddress
            );
            updatedPackages.push({ ...pack, destinationLocation });
          } catch (error) {
            console.error(
              `Error retrieving coordinates for package with id ${pack.id}:`,
              error.message
            );
          }
        }

        setRidePackages(updatedPackages);
      };

      getPackageCoordinates();
    }
  }, [geocodeAddress, packages, rideType]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const from = params.get("from");
    setRideType(from);
  }, [location.search]);

  // console.log(rideShareCo);

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url = `${baseUrl}/retailer/packages/add-package?type=${rideType}`;

    const rideSharePackages = packages.map((rideSharePackage) => {
      const category = categories.find(
        (cat) => cat.id === rideSharePackage.selectedCategory
      );

      return {
        pickupAddress,
        pickupLocation: {
          latitude: pickupLatLng.lat,
          longitude: pickupLatLng.lng,
        },
        destinationAddress: destinationAddress,
        destinationLocation: {
          latitude: rideShareCo.latitude,
          longitude: rideShareCo.longitude,
        },
        destinationContact: rideSharePackage.receiverContact,
        products: [
          {
            images: rideSharePackage.images,
            size: "small",
            name: rideSharePackage.packageName,
            category: category ? category.label : "",
          },
        ],
      };
    });

    const expressPackages = ridePackages.map((expressPackage) => {
      const category = categories.find(
        (cat) => cat.id === expressPackage.selectedCategory
      );

      return {
        pickupAddress,
        pickupLocation: {
          latitude: pickupLatLng.lat,
          longitude: pickupLatLng.lng,
        },
        destinationAddress: expressPackage.destinationAddress,
        destinationLocation: {
          latitude: expressPackage.destinationLocation.latitude,
          longitude: expressPackage.destinationLocation.longitude,
        },
        destinationContact: expressPackage.receiverContact,
        products: [
          {
            images: expressPackage.images,
            size: "small",
            name: expressPackage.packageName,
            category: category ? category.label : "",
          },
        ],
      };
    });

    console.log(rideSharePackages);

    let selectedPackages;

    if (rideType === "express") {
      selectedPackages = expressPackages;
    } else {
      selectedPackages = rideSharePackages;
    }

    try {
      const response = await axios.post(
        url,
        {
          packages: selectedPackages,
        },
        headers
      );

      if (response.status === 200) {
        if (response.data.message === "internal server error") {
          toast.error(response.data.message);
          setLoading(false);
        }
        if (response.data.message === "success") {
          const deliveryId = response.data.data.deliveryId;
          console.log(response.data.data.deliveryId);

          try {
            const res = await axios.get(
              `${baseUrl}/retailer/packages/delivery`,
              headers
            );

            if (res.status === 200) {
              const allPackages = res.data.data;

              const packageArray = allPackages.filter(
                (pack) => pack.deliveryId === deliveryId
              );

              const faresArray = [];

              packageArray.forEach(async (pack) => {
                const packageId = pack.packageId;

                try {
                  const res = await axios.get(
                    `${baseUrl}/retailer/packages/package_costing?id=${packageId}`,
                    headers
                  );

                  const fares = res.data.data;

                  console.log(res.data.data);
                  faresArray.push(fares);

                  const bicycleFare = faresArray.reduce(
                    (accumulator, current) => accumulator + current.bicycle,
                    0
                  );

                  const bikeFare = faresArray.reduce(
                    (accumulator, current) => accumulator + current.okada,
                    0
                  );

                  const carFare = faresArray.reduce(
                    (accumulator, current) => accumulator + current.car,
                    0
                  );

                  const vanFare = faresArray.reduce(
                    (accumulator, current) => accumulator + current.van,
                    0
                  );

                  const tricycleFare = faresArray.reduce(
                    (accumulator, current) => accumulator + current.tricycle,
                    0
                  );

                  sessionStorage.setItem(
                    "bicycleFare",
                    JSON.stringify(bicycleFare)
                  );
                  sessionStorage.setItem("carFare", JSON.stringify(carFare));
                  sessionStorage.setItem("bikeFare", JSON.stringify(bikeFare));
                  sessionStorage.setItem("vanFare", JSON.stringify(vanFare));
                  sessionStorage.setItem(
                    "tricycleFare",
                    JSON.stringify(tricycleFare)
                  );

                  navigate(`/price-fare/${deliveryId}`);
                } catch (error) {
                  console.log(error);
                }
              });
            }
          } catch (error) {
            console.log(error);
          }

          toast.success("Order Placed");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <div className="package-details">
      <div className="express-title">
        <h3>Send Package</h3>
      </div>
      <div className="express-details">
        <div className="express-details-top">
          <div className="package-details-card">
            <h4>Pickup address</h4>
            <p>{pickupAddress}</p>
          </div>
          {rideType === "ride_share" ? (
            destinationAddress ? (
              <div className="package-details-card">
                <h4>Destination address</h4>
                <p>{destinationAddress}</p>
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
        <div className="express-details-center">
          <h3>Package Details</h3>
          {packages.length > 0 ? (
            packages.map((pack, index) => {
              const category = categories.find(
                (category) => category.id === pack.selectedCategory
              );

              const handleDeletePackage = () => {
                removePackage(pack);
              };

              return (
                <div className="package-details-card" key={index}>
                  <h4>Package name: {pack.packageName}</h4>
                  {rideType !== "ride_share" ? (
                    <p>destination: {pack.destinationAddress}</p>
                  ) : null}
                  <p>Category: {category ? category.label : ""}</p>
                  <span onClick={handleDeletePackage}>delete</span>
                </div>
              );
            })
          ) : (
            <h4>No Packages </h4>
          )}
        </div>
        <div className="express-details-bottom">
          <button
            onClick={() => navigate(-1)}
            style={{
              backgroundColor: `${packages.length === 0 ? "#4b925b" : ""}`,
            }}
          >
            Back
          </button>
          {packages.length > 0 ? (
            <button onClick={handlePlaceOrder}>
              {loading ? <ClipLoader color="#fff" size={15} /> : "proceed"}
            </button>
          ) : (
            <button disabled={true} style={{ backgroundColor: "#A7A7A7" }}>
              proceed
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
