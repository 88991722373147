import React, { useEffect, useState } from "react";
import "./pickups.css";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import PickUp from "../../component/pick-up/PickUp";
import { ClipLoader } from "react-spinners";
import axios from "axios";

export default function Pickups() {
  const [selectedOption, setSelectedOption] = useState("all");
  const [allOrder, setAllOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  const baseUrl = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  useEffect(() => {
    const getAllOrder = async () => {
      setLoading(true);
      const res = await axios.get(`${baseUrl}/retailer/packages/delivery`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (res.status === 200) {
        setAllOrder(res.data.data);
        setLoading(false);
      }
    };
    getAllOrder();
  }, [baseUrl, userToken]);

  const options = [
    "all",
    "approved",
    "ASSIGNED_DISPATCHER",
    "accepted by a dispatcher",
    "receipt submitted",
    "completed",
    "declined by driver",
    "cancel order",
  ];

  const filteredArray =
    selectedOption === "all"
      ? allOrder
      : allOrder.filter((item) => item.currentStatus === selectedOption);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredArray.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return (
      <div className="loading_container">
        <ClipLoader size={30} color="#4b925b" />
      </div>
    );
  }

  return (
    <div className="pick-ups">
      <h3 className="express-shipping-title pick-up-title">
        <span>
          <Link to="/dashboard">
            <BiArrowBack />
          </Link>
        </span>
        <span>Pick Ups</span>
      </h3>
      <div className="pick-up-filter">
        <select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="pick-up-info">
        <p>
          Showing {indexOfFirstItem + 1} to{" "}
          {Math.min(indexOfLastItem, filteredArray.length)} of{" "}
          {filteredArray.length} pickups
        </p>
      </div>
      <div className="pick-up-container">
        {currentItems.length > 0 ? (
          currentItems.map((item) => {
            const { _id, currentStatus, packageId } = item;

            return (
              <>
                <PickUp key={_id} status={currentStatus} id={packageId} />
              </>
            );
          })
        ) : (
          <div>
            <p>no pickups</p>
          </div>
        )}
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredArray.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>
    </div>
  );
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination">
      {pageNumbers.map((number) => (
        <li key={number}>
          <button
            className={currentPage === number ? "active" : ""}
            onClick={() => paginate(number)}
          >
            {number}
          </button>
        </li>
      ))}
    </ul>
  );
};
