import React, { useEffect, useState } from "react";
import "./upload-package.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { FaTimesCircle } from "react-icons/fa";
import { useGlobalContext } from "../../context";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import ExpressDestination from "../../component/express-destination/ExpressDestination";

function UploadPackage() {
  const { addPackage, packages } = useGlobalContext();

  const baseUrl = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  const [rideType, setRideType] = useState("");
  const [packageName, setPackageName] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [receiverContact, setReceiverContact] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const navigate = useNavigate();

  const categories = [
    { id: "1", label: "Books, Documents, Files" },
    { id: "2", label: "Food items" },
    { id: "3", label: "Machine" },
    { id: "4", label: "Clothing Accessories" },
    { id: "5", label: "Laptop, Mobile, other gadgets" },
    { id: "6", label: "Jewelry, Other Valuables" },
    { id: "7", label: "Other" },
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const from = params.get("from");
    setRideType(from);
  }, []);

  const handlePackageNameChange = (e) => {
    setPackageName(e.target.value);
  };

  const handleContactChange = (e) => {
    setReceiverContact(e.target.value);
  };

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    const selectedImages = files.slice(0, 4);

    if (selectedImages.length + images.length > 4) {
      toast.error("Maximum of 4 images can be selected");
      return;
    }

    const newImages = selectedImages.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [
      ...prevImages,
      ...newImages.slice(0, 4 - prevImages.length),
    ]);
  };

  const handleImageDelete = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const anotherForm = async (e) => {
    e.preventDefault();
    setLoading(true);

 

    if (
      images.length === 0 ||
      selectedCategory === "" ||
      packageName === "" ||
      receiverContact === ""
    ) {
      setLoading(false);
      setLoadingOne(false);
      toast.error("Input all required fields");
    } else {
      const packageData = {
        packageName,
        receiverContact,
        selectedCategory,
        images,
        destinationAddress,
      };

      setLoading(true);
      addPackage(packageData);

      setTimeout(() => {
        setImages([]);
        setReceiverContact("");
        setSelectedCategory("");
        setPackageName("");
        setDestinationAddress("");
        setLoading(false);
        toast.success("Added Successfully");
      }, 1000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingOne(true);

    const convertBlobToImageFile = async (blobUrl) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", blobUrl, true);
        xhr.responseType = "blob";
        xhr.onload = () => {
          if (xhr.status === 200) {
            const blob = xhr.response;
            const file = new File([blob], "image.png", { type: "image/png" });
            resolve(file);
          } else {
            reject(new Error("Failed to convert blob to image file"));
          }
        };
        xhr.onerror = () => {
          reject(new Error("Failed to convert blob to image file"));
        };
        xhr.send();
      });
    };

    const isInputFieldsFilled = () => {
      return (
        images.length > 0 ||
        selectedCategory ||
        packageName.trim() !== "" ||
        receiverContact.trim() !== "" ||
        destinationAddress.trim() !== ""
      );
    };

    try {
      const isCurrentFieldsFilled = isInputFieldsFilled();
      if ((packages && packages.length > 0) || isCurrentFieldsFilled) {
        const mergedPackages = packages.map((pack) => ({
          ...pack,
          images: [...pack.images],
        }));

        if (isCurrentFieldsFilled) {
          const updatedImages = await Promise.all(
            images.map(async (image, i) => {
              if (image.startsWith("https://res.cloudinary.com")) {
                return image;
              }

              const formData = new FormData();
              const file = await convertBlobToImageFile(
                image,
                `image${i + 1}.jpg`
              );
              formData.append("img", file);

              const options = {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${userToken}`,
                },
              };

              const response = await axios.post(
                `${baseUrl}/retailer/packages/upload-image`,
                formData,
                options
              );

              if (
                response.status === 200 &&
                response.data.message === "success"
              ) {
                return response.data.data.url;
              }
            })
          );

          const updatedPackageData = {
            packageName,
            receiverContact,
            selectedCategory,
            images: updatedImages,
            destinationAddress,
          };

          mergedPackages.push(updatedPackageData);
        }

        for (const pack of mergedPackages) {
          if (!pack.images) {
            continue;
          }

          const updatedImages = [];
          for (let i = 0; i < pack.images.length; i++) {
            const image = pack.images[i];
            if (typeof image === "string") {
              if (image.startsWith("https://res.cloudinary.com")) {
                updatedImages.push(image);
              } else if (image.startsWith("blob:")) {
                const file = await convertBlobToImageFile(image);

                const formData = new FormData();
                formData.append("img", file);

                const options = {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userToken}`,
                  },
                };

                const response = await axios.post(
                  `${baseUrl}/retailer/packages/upload-image`,
                  formData,
                  options
                );

                if (
                  response.status === 200 &&
                  response.data.message === "success"
                ) {
                  const imageUrl = response.data.data.url;
                  updatedImages.push(imageUrl);
                }
              }
            } else {
              updatedImages.push(image);
            }
          }

          pack.images = updatedImages.map((image, i) =>
            typeof image === "string" ? image : pack.images[i]
          );
        }

        sessionStorage.setItem("arc_packages", JSON.stringify(mergedPackages));

        setImages([]);
        setReceiverContact("");
        setSelectedCategory("");
        setPackageName("");
        setDestinationAddress("");
        setLoadingOne(false);
        toast.success("Packages Added, Redirecting to the next page.");

        const allImagesAreCloudinaryURLs = mergedPackages.every((pack) =>
          pack.images.every(
            (image) =>
              typeof image === "string" &&
              image.startsWith("https://res.cloudinary.com")
          )
        );

        if (allImagesAreCloudinaryURLs) {
          window.location.href = `/package-details?from=${rideType}`;
        } else {
          const filteredPackages = mergedPackages.map((pack) => {
            const filteredImages = pack.images.filter(
              (image) =>
                typeof image === "string" &&
                !image.startsWith("https://res.cloudinary.com")
            );
            return {
              ...pack,
              images: filteredImages,
            };
          });

          const blobImagesExist = filteredPackages.some((pack) =>
            pack.images.some((image) => image instanceof File)
          );

          if (blobImagesExist) {
            window.location.href = `/package-details?from=${rideType}`;
          } else {
            window.location.href = `/package-details?from=${rideType}`;
          }
        }
      } else {
        toast.error("Input all required fields");
        setLoading(false);
        setLoadingOne(false);
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      setLoading(false);
      setLoadingOne(false);
      toast.error("Failed to add package");
    }
  };

  return (
    <div className="upload-package">
      <h3 className="upload-package-title">
        <span
          onClick={() => {
            navigate(-1);
            sessionStorage.removeItem("arc_packages");
          }}
        >
          <BiArrowBack />
        </span>
        <span>Package Details </span>
      </h3>
      <form className="upload-package-details">
        <div className="upload-package-details-top">
          <div className="upload-files-top">
            <input
              type="file"
              multiple
              id="upload-file"
              onChange={handleImageSelect}
            />
            <label htmlFor="upload-file">
              <span>Upload pictures</span>
            </label>
          </div>
          <div
            className={
              images.length > 0
                ? "upload-files-bottom image_active"
                : "upload-files-bottom"
            }
          >
            {images.map((image, index) => (
              <div className="upload-package-container" key={index}>
                <img src={image} alt="" className="selected-images" />
                <span onClick={() => handleImageDelete(index)}>
                  <FaTimesCircle />
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="upload-package-details-bottom">
          {rideType !== "ride_share" ? (
            <ExpressDestination
              destinationAddress={destinationAddress}
              setDestinationAddress={setDestinationAddress}
            />
          ) : (
            <></>
          )}
          <div className="upload-package-details-bottom-container">
            <input
              type="text"
              placeholder="Enter package name"
              value={packageName}
              onChange={handlePackageNameChange}
              className="package-name"
            />
            <input
              type="number"
              placeholder="Receiver's Phone Number"
              value={receiverContact}
              onChange={handleContactChange}
              className="package-name"
            />
          </div>

          <h4>Select item category</h4>
          <div className="upload-package-category-container">
            <PackageCategory
              categories={categories}
              handleCategorySelect={handleCategorySelect}
              selectedCategory={selectedCategory}
            />
          </div>
        </div>
        <div className="btn_container">
          {rideType !== "ride_share" ? (
            <div className="upload-category-options">
              {loading ? (
                <button className="btn_another" disabled>
                  <ClipLoader color="#4b925b" size={15} />
                </button>
              ) : (
                <button onClick={anotherForm} className="btn_another">
                  Add another package
                </button>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="upload-category-options">
            {loadingOne ? (
              <button disabled className="btn_continue">
                <ClipLoader color="#fff" size={15} />
              </button>
            ) : (
              <button onClick={handleSubmit} className="btn_continue">
                Confirm package
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default UploadPackage;

function PackageCategory({
  categories,
  selectedCategory,
  handleCategorySelect,
}) {
  return (
    <div className="package-category-container">
      {categories.map(({ id, label }) => (
        <div key={id} className="package-category-item">
          <input
            type="radio"
            id={id}
            value={id}
            checked={selectedCategory === id}
            onChange={() => handleCategorySelect(id)}
          />
          <label htmlFor={id}>{label}</label>
        </div>
      ))}
    </div>
  );
}
