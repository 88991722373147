import React, { useState } from "react";
import "./support.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiWhatsappFill,
} from "react-icons/ri";
import { supportFaq } from "./data";

export default function Support() {
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="support">
      <h3 className="express-package-title pickupID_title">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack />
        </span>
        <span>Contact support</span>
      </h3>
      <div className="support_container">
        <h1 className="support_title">using arc</h1>
        <div className="whatsapp">
          <a href="https://wa.link/pfb0lj">
            <RiWhatsappFill />
          </a>
        </div>
        {supportFaq.map((faq, i) => {
          const isActive = i === activeIndex;
          return (
            <div className="support_card" key={i}>
              <div className="support_top" onClick={() => handleItemClick(i)}>
                <p className="support_card_text">{faq.title}</p>
                <span className="support_icon">
                  {isActive ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
                </span>
              </div>
              <article
                className={isActive ? "support_body active" : "support_body"}
              >
                {typeof faq.body === "string" ? (
                  <span>{faq.body}</span>
                ) : Array.isArray(faq.body) ? (
                  // eslint-disable-next-line array-callback-return
                  faq.body.map((item, i) => {
                    if (faq.title === "How to place an order") {
                      return (
                        <div key={i} className="support_list">
                          <b> Step {i + 1}</b> - {item.desc}
                        </div>
                      );
                    } else if (faq.title === "Using ARC") {
                      return (
                        <div key={i} className="support_list_arc">
                          <div className="support_list_cont">
                            <div className="round"></div>
                            <h3 className="support_list_title">{item.tit}</h3>
                          </div>
                          <p className="support_list_text">{item.desc}</p>
                        </div>
                      );
                    }
                  })
                ) : (
                  ""
                )}
              </article>
            </div>
          );
        })}
      </div>
    </div>
  );
}
