import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import arcLogo from '../../asset/image/arc_logo.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export default function ForgottenForm() {
  const [email, setEmail] = useState('');
  const [isPending, setIsPending] = useState(false);

  const baseuri = process.env.REACT_APP_BASE_URI;

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsPending(true);
    const body = { email };

    try {
      const res = await axios.put(
        `${baseuri}/accounts/forgot_password?email=${email}`,
        body
      );
      if (res.status === 200) {
        if (res.data.status === 'failed') {
          toast.error(res.data.msg);
        } else {
          toast.success(res.data.msg);
          navigate('/login');
        }
        setIsPending(false);
      }
    } catch (error) {
      setIsPending(false);
      toast.error(error.message);
    }
  };

  return (
    <div className="forgotten_form">
      <div className="forgotten_form_top">
        <div className="arc_logo_container">
          <img src={arcLogo} alt="ARC LOGO" className="arc_logo" />{' '}
        </div>
      </div>

      <form className="forgotten_form_bottom" onSubmit={handleRegister}>
        <div className="input_group">
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="input_group">
          {isPending ? (
            <button disabled>
              <ClipLoader color="#fff" />
            </button>
          ) : (
            <button>Register</button>
          )}
        </div>
      </form>
    </div>
  );
}
