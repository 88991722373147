import React, { useEffect, useState } from "react";
import "./regular-shipping.css";
import { Link, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import AddressOptions from "../../component/modals/address/AddressOptions";
import { useGlobalContext } from "../../context";
import axios from "axios";
import { toast } from "react-toastify";
import { geocodeAddress } from "../../helpers/geocode";

const RegularShipping = () => {
  const { addRegularAddresses } = useGlobalContext();

  const apiOptions = {
    key: process.env.REACT_APP_GOOGLE_API_KEY,
  };

  const [addresses, setAddresses] = useState(["", ""]);
  const [addressList, setAddressList] = useState([]);
  const [activeInput, setActiveInput] = useState(true);
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedAddresses = sessionStorage.getItem("arc_addresses");
    if (storedAddresses) {
      const parsedAddresses = JSON.parse(storedAddresses);
      const initialAddresses = parsedAddresses.slice(0, 2);
      setAddresses(initialAddresses);
      setAddresses(["", ""]);
    } else {
      setAddresses(["", ""]);
    }
  }, []);

  const handleInputChange = async (event, index) => {
    const value = event.target.value;
    const newAddresses = [...addresses];
    newAddresses[index] = value;

    if (newAddresses.length > 2) {
      console.log(newAddresses);
      newAddresses.length = 2;
      for (let i = 2; i < addresses.length; i++) {
        newAddresses[i] = "";
      }
    }

    setAddresses(newAddresses);
    setActiveInput(index);
    setShowAddressOptions(true);

    if (value === "") {
      setShowAddressOptions(false);
    }

    if (value.length > 4) {
      try {
        const res = await geocodeAddress(value);

        if (res.addList.length > 0) {
          setAddressList(res.addList);
        }

        // console.log(res);
        // const response = await axios.get(
        //   `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        //     value
        //   )}&key=${apiOptions.key}`
        // );

        // const results = response.data.results;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAddressSelect = (address) => {
    const newAddresses = [...addresses];
    newAddresses[activeInput] = address;
    setAddresses(newAddresses);
    setShowAddressOptions(false);
  };

  const handleOptionSelect = (address) => {
    const newAddresses = [...addresses];
    newAddresses[activeInput] = address;
    setAddresses(newAddresses);
    setShowAddressOptions(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (addresses.some((address) => address.trim() === "")) {
      toast.error("Please fill in all address fields");
      return;
    }

    console.log(addresses);
    addRegularAddresses(addresses);
    navigate("/upload-package?from=ride_share");
  };

  return (
    <section className="express-shipping">
      <div className="express-shipping-container">
        <h3 className="express-shipping-title">
          <span>
            <Link to="/dashboard">
              <BiArrowBack />
            </Link>
          </span>
          <span>Ride Sharing</span>
        </h3>
        <form
          className="express-shippng-address-container"
          onSubmit={handleSubmit}
        >
          {addresses.map((address, index) => (
            <div className="express-shipping-address" key={index}>
              {index === activeInput ? (
                <div className="active-circle"></div>
              ) : (
                <div className="icon-option">
                  <AiOutlineSearch />
                </div>
              )}
              <input
                type="text"
                placeholder={index === 0 ? "Pickup Address" : "Destination"}
                className="express-shipping-input"
                autoFocus={index === 0}
                value={address}
                onChange={(event) => handleInputChange(event, index)}
                onFocus={() => setActiveInput(index)}
              />
            </div>
          ))}
          <button className="express-button">Proceed to package</button>
        </form>

        {showAddressOptions && (
          <AddressOptions
            onOptionSelect={handleOptionSelect}
            onAddressSelect={handleAddressSelect}
            addresses={addressList}
          />
        )}
      </div>
    </section>
  );
};

export default RegularShipping;
