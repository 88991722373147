import React, { useEffect, useState } from "react";
import "./sidebar.css";
import usericon from "../../asset/image/dispatchIcon.jpg";
import { BsBank, BsBoxes, BsTag } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { BiShoppingBag } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { GoLaw } from "react-icons/go";
import { IoIosExit } from "react-icons/io";
import { useGlobalContext } from "../../context";
import { FaHome } from "react-icons/fa";
import axios from "axios";

export default function Sidebar() {
  const { isOpen, logout } = useGlobalContext();
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState("");
  const [wallet, setWallet] = useState("");

  const usermail = sessionStorage.getItem("arc_user_email") || "";
  const first_name = sessionStorage.getItem("arc_first_name") || "";
  const last_name = sessionStorage.getItem("arc_last_name") || "";

  const role = "business_owner";
  const baseUri = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const res = await axios.get(`${baseUri}/retailer/wallet-details`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (res.data.business_name) {
          setBusinessName(res.data.business_name);
          setWallet(res.data.wallet);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUserDetails();
  }, [baseUri, userToken]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <aside className={isOpen ? "sidebar active" : "sidebar"}>
      <div className="sidebar_container">
        <div className="profile_container">
          <img src={usericon} alt="profile pics" className="profile_pics" />
          <div className="profile_info">
            <h4>
              {first_name} {last_name}
            </h4>
            <p>{usermail}</p>
            <div className="business_name">
              <p>{businessName}</p>
              <p>{wallet}</p>
            </div>
          </div>
        </div>
        <div className="border"></div>
        <div className="pages_container">
          <Link to="/dashboard" className="pages_link">
            <span className="page_icon">
              <FaHome />
            </span>
            <span className="page_text">Home</span>
          </Link>
          <Link
            to={role === "business_owner" ? "/" : "/payments"}
            className="pages_link"
          >
            <span className="page_icon">
              <BsBank />
            </span>
            <span className="page_text">Payment</span>
          </Link>
          <Link to="/track" className="pages_link">
            <span className="page_icon">
              <BiShoppingBag />
            </span>
            <span className="page_text">Track a package </span>
          </Link>
          <Link to="/all-pickups" className="pages_link">
            <span className="page_icon">
              <BsBoxes />
            </span>
            <span className="page_text">All pick ups</span>
          </Link>
          <Link to="/" className="pages_link">
            <span className="page_icon">
              <BsTag />
            </span>
            <span className="page_text">Promotions</span>
          </Link>
          <Link to="/settings" className="pages_link">
            <span className="page_icon">
              <FiSettings />
            </span>
            <span className="page_text">Settings</span>
          </Link>
          <div className="border"></div>
          <Link to="/support" className="pages_link">
            <span className="page_icon">
              <AiOutlineQuestionCircle />
            </span>
            <span className="page_text">Contact support</span>
          </Link>
          <Link to="/" className="pages_link">
            <span className="page_icon">
              <GoLaw />
            </span>
            <span className="page_text">Legal</span>
          </Link>
          <div className="border-two"></div>
          <a
            href="https://logistics.allroundcare.org/landing"
            rel="noreferrer"
            target="_blank"
            className="pages_card"
          >
            <h4>Become a dispatch rider</h4>
            <p>Earn money on your own schedule</p>
          </a>
          <div className="pages_link" onClick={handleLogout}>
            <span className="page_icon">
              <IoIosExit />
            </span>
            <span className="page_text">Sign out</span>
          </div>
        </div>
      </div>
    </aside>
  );
}
