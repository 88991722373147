export const supportFaq = [
  {
    title: "Using ARC",
    body: [
      {
        tit: "Revolutionizing Logistics for Efficiency and Effectiveness",
        desc: "In a world where the rapid movement of goods is a crucial aspect of modern life, ARC stands as a beacon of innovation and reliability. ARC, an acronym for &quot;Advanced Resourceful Cargo,&quot; is a cutting-edge logistic platform that has redefined the way goods are transported, stored, and distributed, ensuring seamless connectivity from the point of origin to the final consumer. In this comprehensive write-up, we will delve into the myriad features and benefits that make ARC the go-to choice for all your logistical needs.",
      },
      {
        tit: "A Unified Platform for All Logistics Needs",
        desc: "ARC is more than just a logistics platform; it&#39;s a comprehensive solution designed to cater to a wide spectrum of logistics requirements. Whether you need to dispatch small errands or handle massive truckloads of commodities, ARC has you covered. With ARC, you can expect a seamless, hassle-free experience, regardless of the scale or complexity of your logistical needs.",
      },
      {
        tit: "Efficiency at Its Core",
        desc: "At the heart of ARC's success lies a commitment to efficiency. The platform leverages state-of-the-art technology and a meticulously designed logistics infrastructure to ensure that your goods are moved with unparalleled speed and precision. This efficiency is particularly valuable in today&#39;s fast-paced business environment, where timely deliveries can make all the difference.",
      },
      {
        tit: "Safe and Secure Storage",
        desc: "ARC takes pride in offering secure and reliable storage solutions. We understand the importance of safeguarding your valuable cargo during transit and storage. With our top-notch storage facilities, you can rest assured that your goods are protected from any harm, theft, or damage.",
      },
      {
        tit: "Seamless Distribution",
        desc: "One of ARC's standout features is its impeccable distribution network. We've established a far-reaching and reliable distribution system that ensures your goods reach their intended destination, no matter how remote it may be. This comprehensive coverage is a testament to our commitment to customer satisfaction.",
      },
      {
        tit: "Tailored Services",
        desc: "We recognize that every logistics requirement is unique. That's why ARC offers a range of tailored services to suit your specific needs. Whether you require express deliveries, temperature-sensitive transportation, or specialized handling, we have the expertise and resources to accommodate your requests..",
      },
      {
        tit: "A Platform for the Future",
        desc: "ARC is not just about meeting today's logistics needs; it&#39;s about shaping the future of the industry. We continuously invest in research and development to stay at the forefront of technological advancements. By doing so, we are well-prepared to adapt to emerging trends and ensure that our customers benefit from the latest innovations in logistics.",
      },
    ],
  },
  {
    title: "How to place an order",
    body: [
      {
        desc: "Placing an order on our e-hailing logistic app is very easy, and we are here to show you how to do it.",
      },
      {
        desc: "Placing an order on our e-hailing logistic app is very easy, and we are here to show you how to do it.",
      },
      {
        desc: "Download the app on your mobile device and create an account if you haven't already.",
      },
      {
        desc: "Before placing your order, ensure that your pick-up and drop-off locations have been correctly inputted..",
      },
      {
        desc: "Choose your preferred service and vehicle type from the available options.",
      },
      {
        desc: "Indicate whether you want your package delivered immediately or at a specific time.",
      },
      {
        desc: "Review your order details including the price before confirming your order.",
      },
      {
        desc: "Once your order is confirmed, you will receive real-time updates on your driver's location and estimated arrival time.",
      },
      {
        desc: "Once your package is successfully delivered, you will receive an alert to confirm the delivery.",
      },
    ],
  },
  {
    title: "Wrong pickup location",
    body: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet praesentium nihil architecto beatae quaerat ratione! Facere doloribus eum a iste officia repellat, quaerat fuga dolore accusamus atque aliquid, voluptas dolor dolorum saepe tempore assumenda obcaecati molestiae fugit illo doloremque temporibus!",
  },
  {
    title: "Payment issues",
    body: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet praesentium nihil architecto beatae quaerat ratione! Facere doloribus eum a iste officia repellat, quaerat fuga dolore accusamus atque aliquid, voluptas dolor dolorum saepe tempore assumenda obcaecati molestiae fugit illo doloremque temporibus!",
  },
  {
    title: "Other services",
    body: " Lorem ipsum dolor sit amet consectetur, adipisicing elit. Amet praesentium nihil architecto beatae quaerat ratione! Facere doloribus eum a iste officia repellat, quaerat fuga dolore accusamus atque aliquid, voluptas dolor dolorum saepe tempore assumenda obcaecati molestiae fugit illo doloremque temporibus!",
  },
];
