import React, { useState } from "react";
import "./express-destination.css";
import axios from "axios";
import { FaMapMarkerAlt } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { geocodeAddress } from "../../helpers/geocode";

export default function ExpressDestination({
  destinationAddress,
  setDestinationAddress,
}) {
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const apiOptions = {
    key: process.env.REACT_APP_GOOGLE_API_KEY,
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    let newAddresses = destinationAddress;
    newAddresses = value;

    setDestinationAddress(newAddresses);

    if (value === "") {
      setShowAddressOptions(false);
    }

    if (value.length > 4) {
      setLoading(true);
      setShowAddressOptions(true);
      try {
        const res = await geocodeAddress(value);

        console.log(res);

        if (res.addList.length > 0) {
          setLoading(false);
          setAddressList(res.addList);
        }
        // const response = await axios.get(
        //   `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        //     value
        //   )}&key=${apiOptions.key}`
        // );

        // const results = response.data.results;
        // if (results.length > 0) {
        //   setLoading(false);
        //   setAddressList(results);
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAddressSelect = (address) => {
    let newAddresses = destinationAddress;
    newAddresses = address;
    setDestinationAddress(newAddresses);
    setShowAddressOptions(false);
  };

  const handleAddressClick = (address) => {
    handleAddressSelect(address.display_name);
  };

  return (
    <div className="express-destination">
      <h3>enter drop location</h3>
      <div className="express-destination-addressInput">
        <input
          type="text"
          value={destinationAddress}
          onChange={(e) => handleInputChange(e)}
        />
      </div>
      {showAddressOptions && (
        <section className="express-destination-history">
          {!loading ? (
            addressList.slice(0, 4).map((address, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className="address-details"
                    onClick={() => handleAddressClick(address)}
                  >
                    <div className="address-icon">
                      <FaMapMarkerAlt />
                    </div>
                    <div className="details">
                      <b>{address.display_name}</b>
                    </div>
                  </div>
                  <div className="border-bottom"></div>
                </React.Fragment>
              );
            })
          ) : (
            <div className="express_loading">
              <ClipLoader color="#A7A7A7" />
            </div>
          )}
        </section>
      )}
    </div>
  );
}
