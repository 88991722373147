import React, { useEffect, useState } from "react";
import DispatchID from "../../component/dispatchID/DispatchID";
import "./tracking.css";
import Sidebar from "../../component/sidebar/Sidebar";
import { useGlobalContext } from "../../context";
import { FaBars } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";

function Tracking() {
  const { orderId, ride_choice } = useParams();

  const { openSidebar, closeSidebar } = useGlobalContext();
  const [riders, setRiders] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [pollingInterval, setPollingInterval] = useState(null);
  const [pickupLocation, setPickupLocation] = useState({});

  const baseUrl = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  useEffect(() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    };

    const getAllTrips = async () => {
      const res = await axios.get(
        `${baseUrl}/retailer/packages/delivery`,
        headers
      );

      const allPackages = res.data.data;
      const packageObject = allPackages.find(
        // eslint-disable-next-line eqeqeq
        (pack) => pack.deliveryId == orderId
      );

      setPickupLocation(packageObject.pickupLocation);
    };

    const getAllOrder = async () => {
      setLoading(true);

      const url = `${baseUrl}/retailer/drivers?lat=${pickupLocation.latitude}&long=${pickupLocation.longitude}`;

      try {
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (res.status === 200) {
          setLoading(false);
          setRiders(res.data.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getAllTrips();
    getAllOrder();
  }, [
    baseUrl,
    orderId,
    pickupLocation.latitude,
    pickupLocation.longitude,
    userToken,
  ]);

  console.log(riders);

  const ridersChoice = riders.filter(
    (rides) => rides.vehicle_type === ride_choice
  );
  console.log(ridersChoice);

  if (loading) {
    return (
      <div className="loading_container">
        <ClipLoader size={30} color="#4b925b" />
      </div>
    );
  }

  return (
    <>
      <div className="menu_icon" onClick={openSidebar}>
        <FaBars />
      </div>

      <div className="tracking" onClick={closeSidebar}>
        <div className="tracking_title">
          <h1>All {ride_choice} online drivers</h1>
        </div>
        {!loading && riders.length === 0 ? (
          <div className="ride-offline">
            <h4>
              No Dispatcher online? No worries. Click on the button below to
              find riders close to you.
            </h4>
            <span>
              <Link to="/offline-riders">Find Riders</Link>
            </span>
          </div>
        ) : !loading ? (
          riders.map((rider) => (
            <div className="ride-options" key={rider.driver_id}>
              <DispatchID
                key={rider.driver_id}
                riderName={`${rider.first_name} ${rider.last_name}`}
                riderId={rider.driver_id}
                phone={rider.phone}
                time={rider.time}
                type={rider.vehicle_type}
                price={rider.price}
                rideType={rider.rideType}
                profile_pics={rider.profile_pics}
                orderId={orderId}
              />
            </div>
          ))
        ) : (
          <div className="loading_container">
            <ClipLoader size={30} color="#4b925b" />
          </div>
        )}

        <Sidebar />
      </div>
    </>
  );
}

export default Tracking;
