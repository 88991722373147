import React from "react";
import { Link } from "react-router-dom";
import LoginForm from "../../component/login-form/LoginForm";
import "./login.css";

function Login() {
  return (
    <div className="login">
      <div className="login_container">
        <LoginForm />
        <div className="form_bottom">
          <div className="form_bottom_text">
            <Link to="/forget-password">Forgot password?</Link>
          </div>
          <div className="form_bottom_text">
            <Link to="/register">Don't have an account? Register</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
