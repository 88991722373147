import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./prices.css";
import { IoIosBicycle } from "react-icons/io";
import { BsCarFrontFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaMotorcycle } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { HiOutlineTruck } from "react-icons/hi";

export default function Prices() {
  const navigate = useNavigate();

  const { deliveryID } = useParams();

  const handleClick = (cardName) => {
    navigate(`/finding-dispatch/${cardName}/${deliveryID}`);
  };

  const bicycleFare = JSON.parse(sessionStorage.getItem("bicycleFare"));
  const carFare = JSON.parse(sessionStorage.getItem("carFare"));
  const bikeFare = JSON.parse(sessionStorage.getItem("bikeFare"));
  const vanFare = JSON.parse(sessionStorage.getItem("vanFare"));
  const tricycleFare = JSON.parse(sessionStorage.getItem("tricycleFare"));

  return (
    <div className="prices">
      <div className="prices_title">
        <h1>Select your preferred mode of dispatch</h1>
      </div>

      <div className="prices_container">
        <div className="prices_card" onClick={() => handleClick("bicycle")}>
          <div className="left_price_card">
            <span>
              <IoIosBicycle className="type_icon" />
            </span>
            <span>Bicycle/Scooter</span>
          </div>
          <div className="right_price_card">
            <span>₦{Math.ceil(bicycleFare / 10) * 10}</span>
          </div>
          <div className="right_price_icon">
            <BsFillArrowRightCircleFill />
          </div>
        </div>

        <div className="prices_card" onClick={() => handleClick("Bike")}>
          <div className="left_price_card">
            <span>
              <FaMotorcycle className="type_icon" />
            </span>
            <span>bike</span>
          </div>
          <div className="right_price_card">
            <span>₦{Math.ceil(bikeFare / 10) * 10}</span>
          </div>
          <div className="right_price_icon">
            <BsFillArrowRightCircleFill />
          </div>
        </div>
        <div className="prices_card" onClick={() => handleClick("Car")}>
          <div className="left_price_card">
            <span>
              <BsCarFrontFill className="type_icon" />
            </span>
            <span>Car</span>
          </div>
          <div className="right_price_card">
            <span>₦{Math.ceil(carFare / 10) * 10}</span>
          </div>
          <div className="right_price_icon">
            <BsFillArrowRightCircleFill />
          </div>
        </div>

        <div className="prices_card" onClick={() => handleClick("tricycle")}>
          <div className="left_price_card">
            <span>
              <HiOutlineTruck className="type_icon" />
            </span>
            <span>tricycle</span>
          </div>
          <div className="right_price_card">
            <span>₦{Math.ceil(tricycleFare / 10) * 10}</span>
          </div>
          <div className="right_price_icon">
            <BsFillArrowRightCircleFill />
          </div>
        </div>

        <div className="prices_card" onClick={() => handleClick("van")}>
          <div className="left_price_card">
            <span>
              <FiTruck className="type_icon" />
            </span>
            <span>van</span>
          </div>
          <div className="right_price_card">
            <span>₦{Math.ceil(vanFare / 10) * 10} </span>
          </div>
          <div className="right_price_icon">
            <BsFillArrowRightCircleFill />
          </div>
        </div>
      </div>

      <div className="prices_back_btn">
        <button onClick={() => navigate(-1)}>Go back</button>
      </div>
    </div>
  );
}
