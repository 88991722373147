import React, {
  useReducer,
  useContext,
  createContext,
  useEffect,
  useState,
} from "react";

const AppContext = createContext();

const initialState = {
  isOpen: false,
  item: [],
  userLocation: null,
  activeInput: false,
  addHistory: false,
  addOptions: false,
  packages: [],
  regularAddresses: [],
  expressAddresses: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "OPEN_SIDEBAR":
      return { ...state, isOpen: true };
    case "CLOSE_SIDEBAR":
      return { ...state, isOpen: false };
    case "SET_USER_LOCATION":
      return { ...state, userLocation: action.payload };
    case "SET_ADDRESS_HISTORY":
      return { ...state, addHistory: true };
    case "SET_ADDRESS_HISTORY_CLOSE":
      return { ...state, addHistory: false };
    case "SET_ADDRESS_OPTIONS":
      return { ...state, addOptions: true };
    case "SET_ADDRESS_OPTIONS_CLOSE":
      return { ...state, addOptions: false };
    case "SET_REGULAR_ADDRESSES":
      return { ...state, regularAddresses: action.payload };
    case "SET_EXPRESS_ADDRESSES":
      return { ...state, expressAddresses: action.payload };
    case "ADD_ITEM":
      return { ...state, items: [...state.items, action.payload] };
    case "REMOVE_ITEM":
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload.id),
      };
    case "ADD_PACKAGE":
      return {
        ...state,
        packages: [...state.packages, action.payload],
      };
    case "REMOVE_PACKAGE":
      return {
        ...state,
        packages: state.packages.filter((pack) => pack.id !== action.payload),
      };

    default:
      throw new Error();
  }
}

const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) =>
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        (error) => reject(error)
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

const AppProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  const [locationPermission, setLocationPermission] = useState("unknown");

  const storedPackages = JSON.parse(sessionStorage.getItem("arc_packages"));

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    packages: storedPackages || [],
  });

  useEffect(() => {
    sessionStorage.setItem("arc_packages", JSON.stringify(state.packages));
  }, [state.packages]);

  useEffect(() => {
    const checkLocationPermission = async () => {
      if (navigator.permissions && navigator.permissions.query) {
        try {
          const permissionStatus = await navigator.permissions.query({
            name: "geolocation",
          });
          setLocationPermission(permissionStatus.state);

          permissionStatus.onchange = () => {
            setLocationPermission(permissionStatus.state);
          };
        } catch (error) {
          console.error("Error checking location permission:", error);
        }
      }
    };

    checkLocationPermission();
  }, []);

  const handleGrantAccess = () => {
    if (locationPermission === "denied" || locationPermission === "prompt") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocationPermission("granted");
            setLocation({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          (error) => {
            console.error("Error getting user location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  };

  useEffect(() => {
    if (locationPermission === "granted") {
      getUserLocation()
        .then((userLocation) => {
          setLocation(userLocation);
        })
        .catch((error) => {
          console.error("Error getting user location:", error);
        });
    }
  }, [locationPermission]);

  const openSidebar = () => {
    dispatch({ type: "OPEN_SIDEBAR" });
  };

  const closeSidebar = () => {
    dispatch({ type: "CLOSE_SIDEBAR" });
  };

  const openAddressHistory = () => {
    dispatch({ type: "SET_ADDRESS_HISTORY" });
  };

  const closeAddressHistory = () => {
    dispatch({ type: "SET_ADDRESS_HISTORY_CLOSE" });
  };

  const openAddressOption = () => {
    dispatch({ type: "SET_ADDRESS_OPTIONS" });
  };

  const closeAddressOption = () => {
    dispatch({ type: "SET_ADDRESS_OPTIONS_CLOSE" });
  };

  const addPackage = (packageData) => {
    const storedPackages = sessionStorage.getItem("arc_packages");
    let parsedPackages = [];
    let newId = 1;

    if (storedPackages) {
      parsedPackages = JSON.parse(storedPackages);
      newId =
        parsedPackages.length > 0
          ? parsedPackages[parsedPackages.length - 1].id + 1
          : 1;
    }

    let packageExists = false;
    const updatedPackages = parsedPackages.map((existingPackage) => {
      if (existingPackage.packageName === packageData.packageName) {
        packageExists = true;
        const images = Array.isArray(existingPackage.images)
          ? existingPackage.images
          : [];
        return {
          ...existingPackage,
          images: [...images, packageData.imageUrl],
        };
      }
      return existingPackage;
    });

    const newPackageData = { ...packageData, id: newId };

    if (packageExists) {
      sessionStorage.setItem("arc_packages", JSON.stringify(updatedPackages));
    } else {
      const newPackage = {
        packageName: packageData.packageName,
        selectedCategory: packageData.selectedCategory,
        images: [packageData.imageUrl],
        id: newId,
      };
      const newPackages = [...parsedPackages, newPackage];
      sessionStorage.setItem("arc_packages", JSON.stringify(newPackages));
    }

    dispatch({ type: "ADD_PACKAGE", payload: newPackageData });
  };

  const removePackage = (packageData) => {
    dispatch({ type: "REMOVE_PACKAGE", payload: packageData.id });
  };

  const addRegularAddresses = (addressData) => {
    dispatch({ type: "SET_REGULAR_ADDRESSES", payload: addressData });
    sessionStorage.setItem("arc_addresses", JSON.stringify(addressData));
  };

  const addExpressAddresses = (addressData) => {
    dispatch({ type: "SET_EXPRESS_ADDRESSES", payload: addressData });
    sessionStorage.setItem("arc_addresses", JSON.stringify(addressData));
  };

  const logout = () => {
    localStorage.removeItem("userToken");
    sessionStorage.removeItem("arc_addresses");
    sessionStorage.removeItem("arc_packages");
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        openSidebar,
        closeSidebar,
        openAddressHistory,
        openAddressOption,
        closeAddressOption,
        closeAddressHistory,
        addPackage,
        removePackage,
        addRegularAddresses,
        addExpressAddresses,
        logout,
        location,
        locationPermission,
        handleGrantAccess,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
