import React, { useState } from "react";
import "./track.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";

export default function Track() {
  const navigate = useNavigate();
  const [packageID, setPackageID] = useState();
  const [loading, setLoading] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  const handleSend = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const res = await axios.get(
        `${baseUrl}/retailer/packages/tracking?id=${packageID}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (res.data.data !== null) {
        window.location.href = `/track-package/${packageID}`;
        // navigate(`/track-package/${packageID}`);
        setLoading(false);
      } else {
        toast.error("Error finding package: " + packageID);
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="track">
      <h3 className="track-title">
        <span onClick={() => navigate(-1)}>
          <BiArrowBack />
        </span>
        <span>Track your package</span>
      </h3>
      <div className="track-container">
        <div className="tracking-box">
          <label htmlFor="">Package ID</label>
          <input
            type="text"
            value={packageID}
            onChange={(e) => setPackageID(e.target.value)}
          />
          {!loading ? (
            <button onClick={handleSend}>Seach Package</button>
          ) : (
            <button disabled>
              <ClipLoader color="#fff" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
