import React from "react";
import "./dashboard.css";
import { FaBars } from "react-icons/fa";
import Sidebar from "../../component/sidebar/Sidebar";
import { useGlobalContext } from "../../context";
import { Link } from "react-router-dom";
import Map from "../../component/map/Map";
import LocationPrompt from "../../component/location-prompt/LocationPrompt";
import InstallButton from "../../component/install-button/InstallButton";
import LeafletMap from "../../component/map/LeafletMap";

export default function Dashboard() {
  const {
    openSidebar,
    closeSidebar,
    isOpen,
    location,
    locationPermission,
    handleGrantAccess,
  } = useGlobalContext();

  const key = process.env.REACT_APP_GOOGLE_API_KEY;
  const { lat, lng } = location || {};

  if (lat === undefined || lng === undefined) {
    return <div></div>;
  }

  return (
    <>
      <div className="menu_icon" onClick={openSidebar}>
        <FaBars />
      </div>
      <InstallButton />
      <section
        className={isOpen ? "dashboard overlay" : "dashboard"}
        onClick={closeSidebar}
      >
        <Sidebar />

        <div className="map-area">
          {locationPermission === "prompt" ? (
            <LocationPrompt handleGrantAccess={handleGrantAccess} />
          ) : (
            <LeafletMap
              lat={locationPermission === "denied" ? 6.5244 : lat}
              lng={locationPermission === "denied" ? 3.3792 : lng}
              apiKey={key}
              zoom={15}
            />
          )}
        </div>

        <div className="dispatch-option">
          <h4>Pick A Dispatch Mode</h4>
          <div className="dispatch-option-container">
            <div className="dispatch-option-card">
              <Link to="/ride-sharing">Ride Sharing</Link>
            </div>
            <div className="dispatch-option-card">
              <Link to="/express-shipping">Express Shipping</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
