import React, { useState, useEffect } from 'react';
import './install-button.css';

const InstallButton = () => {
  const [showButton, setShowButton] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(display-mode: browser)');
    setShowButton(mediaQuery.matches);

    const handleBeforeInstallPrompt = (event) => {
      console.log('beforeinstallprompt event fired');
      event.preventDefault();
      setDeferredPrompt(event);
      setShowButton(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    console.log('clicked');
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User installed the app');
          } else {
            console.log('User dismissed the install prompt');
          }
          setDeferredPrompt(null);
        })
        .catch((error) => {
          console.error('Error occurred while handling install prompt:', error);
        });
    }
  };

  return (
    <div>
      {showButton && (
        <button onClick={handleInstallClick} className="install-button">
          Install App
        </button>
      )}
    </div>
  );
};

export default InstallButton;
