import React, { useState, useEffect } from "react";
import "./productdetails.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

function ProductDetails() {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [allImages, setAllImages] = useState([]);

  const { id } = useParams();

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URI;
  const userToken = localStorage.getItem("arc_user_token");

  useEffect(() => {
    try {
      setLoading(true);
      const getAllOrder = async () => {
        const res = await axios.get(`${baseUrl}/retailer/packages/delivery`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (res.status === 200) {
          const allOrder = res.data.data;
          const order = allOrder.find((item) => item.packageId === id);

          const allImages = order.products.reduce((acc, product) => {
            return acc.concat(product.images);
          });

          setAllImages(allImages);
          setSelectedImage(allImages.images[0]);

          setOrder(order);
          setLoading(false);
        } else {
          toast.console.error(res.data.message);
          navigate(-1);
        }
      };
      getAllOrder();
    } catch (error) {
      console.log(error);
    }
  }, [baseUrl, id, navigate, userToken]);

  const handleImageClick = (event) => {
    setSelectedImage(event.target.src);
  };

  console.log(allImages);

  if (loading) {
    return (
      <div className="loading_container">
        <ClipLoader size={60} color="#4b925b" />
      </div>
    );
  }

  return (
    <div className="pickupID">
      <h3 className="express-shipping-title pickupID_title">
        <span onClick={() => navigate(-1)}>
          <Link to="">
            <BiArrowBack />
          </Link>
        </span>
        <span>Pick Ups</span>
      </h3>
      <div className="pickupID_container">
        <div className="pickupID_image_container">
          <div className="pickupID_lg_image_box">
            <img src={selectedImage} alt="" className="bg_image" />
          </div>
          <div className="pickupID_images_container">
            {allImages.images.map((image, index) => (
              <div className="pickupID_sm_image_box" key={index}>
                <img
                  src={image}
                  alt=""
                  className="sm_image"
                  onClick={handleImageClick}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="pickupID_details">
          <div className="pickupID_details_content">
            <h4>Package ID</h4>
            <p> {order.packageId}</p>
          </div>
          <div className="pickupID_details_content">
            <h4>Cost</h4>
            <p>₦{Math.ceil(order.cost).toFixed(2)}</p>
          </div>
          <div className="pickupID_details_content">
            <h4>Current Status</h4>
            <p>{order.currentStatus}</p>
          </div>

          <div className="pickupID_details_content">
            <h4>Receiver's Number</h4>
            <p>
              <a href={`${order.destinationContact}`}>
                {order.destinationContact}
              </a>
            </p>
          </div>
          <div className="pickupID_details_content">
            <h4>Pickup address</h4>
            <p>{order.pickupAddress}</p>
          </div>
          <div className="pickupID_details_content">
            <h4>Drop Location</h4>
            <p>{order.destinationAddress}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
